import React, { useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { NotificationContext } from "../context/NotificationContext";

const ToastNotifications = () => {
  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } =
    useContext(NotificationContext);

  return (
    <ToastContainer position="top-end" className="p-3" style={{ zIndex: 9999 }}>
      {/* Success Toast */}
      <Toast
        onClose={() => setSuccessMessage(null)}
        show={!!successMessage}
        delay={5000}
        autohide
        bg="success"
      >
        <Toast.Header closeButton>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{successMessage}</Toast.Body>
      </Toast>

      {/* Error Toast */}
      <Toast
        onClose={() => setErrorMessage(null)}
        show={!!errorMessage}
        delay={5000}
        autohide
        bg="danger"
      >
        <Toast.Header closeButton>
          <strong className="me-auto">Error</strong>
        </Toast.Header>
        <Toast.Body className="text-white">{errorMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotifications;
