import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import firebaseAuthService from "../FirebaseAuthService";
import { functions } from "../FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./SignUpForm.css"; // Custom styles

function SignUpForm() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    displayName: "",
  });
  const [incomingEmail, setIncomingEmail] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    if (emailParam) {
      setFormData((prevData) => ({ ...prevData, email: emailParam }));
      setIncomingEmail(true);
    }
  }, [location.search]);

  async function handleSignUp(event) {
    event.preventDefault();

    const { email, password, displayName } = formData;

    try {
      // // Call the Cloud Function to check if the email is denied
      // const checkIfEmailDenied = httpsCallable(functions, "checkIfEmailDenied");
      // const result = await checkIfEmailDenied({ email });

      // if (result.data.isDenied) {
      //   alert("This email has been denied access. Please contact support.");
      //   return;
      // }

      // Proceed with registration
      const userCredential = await firebaseAuthService.registerUser(
        email,
        password
      );
      const user = userCredential.user;

      // Update the user's display name
      await firebaseAuthService.updateUserProfile(user, { displayName });

      // Send email verification
      await firebaseAuthService.sendEmailVerificationToUser(user);

      // Call the Cloud Function to create the user document in Firestore
      const createUserRecord = httpsCallable(functions, "createUserRecord");
      await createUserRecord({
        email: user.email,
        displayName: displayName,
        phoneNumber: user.phoneNumber,
        providerId: user.providerId,
        providerData: user.providerData,
      });

      setFormData({ email: "", password: "", displayName: "" });

      // Navigate to email verification notice page
      navigate("/verify-email");
    } catch (error) {
      alert(error.message);
    }
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <Form onSubmit={handleSignUp} className="signup-form">
            <Form.Group controlId="formDisplayName" className="mb-3">
              <Form.Label>Name (First and Last)</Form.Label>
              <Form.Control
                type="text"
                name="displayName"
                required
                value={formData.displayName}
                onChange={handleInputChange}
                placeholder="First and Last Name"
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
                readOnly={incomingEmail}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
              />
            </Form.Group>

            <Form.Group controlId="formTerms" className="mb-3">
              <Form.Check
                required
                label={
                  <>
                    I agree to the <Link to="/terms">Terms of Service</Link> and{" "}
                    <Link to="/privacy">Privacy Policy</Link>.
                  </>
                }
              />
            </Form.Group>

            <div className="button-box text-center">
              <Button variant="primary" type="submit" className="signup-button">
                Sign Up
              </Button>
            </div>

            <div className="text-center mt-3">
              <p>
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SignUpForm;
