import React from "react";
import { Container } from "react-bootstrap";

function AccessDenied() {
  return (
    <Container className="mt-5 text-center">
      <h2>Access Denied</h2>
      <p>
        Your account has been denied access. Please contact support if you
        believe this is an error.
      </p>
    </Container>
  );
}

export default AccessDenied;
