import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import firebaseAuthService from "../FirebaseAuthService";
import "./Header.css";

function Header() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await firebaseAuthService.logoutUser();
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
      // Optionally display an error message to the user
    }
  }

  // Helper functions to check user roles
  const isAdmin = currentUser?.role === "admin";
  const isOwner = currentUser?.role === "owner";
  const isMechanic = currentUser?.role === "mechanic";
  const isOperator = currentUser?.role === "operator";
  //const isAuthenticated = !!currentUser;

  return (
    <>
      {/* Title and Subtitle Section */}
      <div className="header-top">
        <Container className="header-top-container">
          <div className="header-title">Pure Flow Solutions</div>
          <div className="header-subtitle">Customer Portal</div>
        </Container>
      </div>
      <div className="header-line"></div>

      {/* Navigation Menu Section */}
      <Navbar bg="dark" variant="dark" expand="lg" className="header-navbar">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto header-nav">
              {isAdmin && (
                <>
                  <Nav.Link href="/dashboard" className="header-nav-link">
                    Dashboard
                  </Nav.Link>
                  <Nav.Link href="/companies" className="header-nav-link">
                    Companies
                  </Nav.Link>
                  <Nav.Link href="/users" className="header-nav-link">
                    Users
                  </Nav.Link>
                  <Nav.Link href="/equipment" className="header-nav-link">
                    Equipment
                  </Nav.Link>
                  <Nav.Link
                    href="/equipment-report"
                    className="header-nav-link"
                  >
                    Equipment Report
                  </Nav.Link>
                  <Nav.Link href="/admin/approvals" className="header-nav-link">
                    Approvals
                  </Nav.Link>
                </>
              )}
              {isOwner && (
                <>
                  <Nav.Link href="/dashboard" className="header-nav-link">
                    Dashboard
                  </Nav.Link>
                  <Nav.Link href="/equipment" className="header-nav-link">
                    Equipment
                  </Nav.Link>
                  <Nav.Link href="/users" className="header-nav-link">
                    Users
                  </Nav.Link>
                </>
              )}
              {isMechanic && (
                <>
                  <Nav.Link href="/dashboard" className="header-nav-link">
                    Dashboard
                  </Nav.Link>
                  <Nav.Link href="/equipment" className="header-nav-link">
                    Equipment
                  </Nav.Link>
                </>
              )}
              {isOperator && (
                <>
                  <Nav.Link href="/equipment" className="header-nav-link">
                    Equipment
                  </Nav.Link>
                </>
              )}
              {currentUser ? (
                <NavDropdown
                  title={currentUser.email}
                  id="user-nav-dropdown"
                  align="end"
                >
                  <NavDropdown.Item href="/account">Account</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link href="/login" className="header-nav-link">
                  Login
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
