import React, { createContext, useState } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  // Toast States
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Error Modal State
  const [modalError, setModalError] = useState(null);

  // Function to show success toast
  const showSuccess = (message) => {
    setSuccessMessage(message);
  };

  // Function to show error toast
  const showError = (message) => {
    setErrorMessage(message);
  };

  // Function to show error modal
  const showModalError = (message) => {
    setModalError(message);
  };

  // Function to hide modal error
  const hideModalError = () => {
    setModalError(null);
  };

  return (
    <NotificationContext.Provider
      value={{
        successMessage,
        setSuccessMessage,
        errorMessage,
        setErrorMessage,
        modalError,
        setModalError,
        showSuccess,
        showError,
        showModalError,
        hideModalError,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
