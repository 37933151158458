import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import firebaseService from "../FirebaseService";
import { useNavigate } from "react-router-dom";
import "./DashboardStyles.css";

function AdminDashboard() {
  const { currentUser } = useAuth();
  const [companyCount, setCompanyCount] = useState(0);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [overdueFiltersData, setOverdueFiltersData] = useState([]);

  const navigate = useNavigate();

  const isAdmin = currentUser?.role === "admin";
  const isOwner = currentUser?.role === "owner";
  const isMechanic = currentUser?.role === "mechanic";

  useEffect(() => {
    if (isAdmin || isOwner || isMechanic) {
      async function fetchMetrics() {
        try {
          // Fetch companies count
          if (isAdmin) {
            const companiesData = await firebaseService.getAllCompanies();
            setCompanyCount(companiesData.length);
          }

          // Fetch equipment count
          let equipmentData;
          if (isAdmin) {
            equipmentData = await firebaseService.getAllEquipment();
          } else {
            equipmentData = await firebaseService.getEquipmentByCompanyId(
              currentUser?.companyId
            );
          }
          setEquipmentCount(equipmentData.length);
        } catch (error) {
          console.error("Error fetching metrics:", error);
        }
      }

      async function fetchOverdueFiltersData() {
        try {
          let companiesData;
          if (isAdmin) {
            companiesData = await firebaseService.getAllCompanies();
          } else {
            const company = await firebaseService.getCompanyById(
              currentUser.companyId
            );
            companiesData = [company];
          }

          const companyOverdueData = [];

          for (const company of companiesData) {
            const companyId = company.id;
            const equipmentData = await firebaseService.getEquipmentByCompanyId(
              companyId
            );

            const equipmentIds = equipmentData.map((e) => e.id);

            // Fetch the latest service logs for all equipment
            const latestServiceLogs =
              await firebaseService.getLatestServiceLogs(equipmentIds);

            // Initialize counters
            let greenCount = 0;
            let yellowCount = 0;
            let redCount = 0;

            for (const equipment of equipmentData) {
              const equipmentId = equipment.id;
              const latestServiceLog = latestServiceLogs[equipmentId];

              let filterChangeDate;
              if (
                latestServiceLog &&
                latestServiceLog.filterChangeDate &&
                latestServiceLog.filterChangeDate.toDate
              ) {
                filterChangeDate = latestServiceLog.filterChangeDate.toDate();
              } else {
                // If no service log, you can decide how to handle it
                filterChangeDate = null;
              }

              let totalEngineHours = 0;

              if (filterChangeDate) {
                // Fetch fuel logs since filterChangeDate
                const fuelLogs = await firebaseService.getFuelLogsSinceDate(
                  equipmentId,
                  filterChangeDate
                );
                // Sum engineHours
                totalEngineHours = fuelLogs.reduce(
                  (sum, log) => sum + log.engineHours,
                  0
                );
              }

              // Calculate days since filterChangeDate
              let daysSinceFilterChange;
              if (filterChangeDate) {
                const currentDate = new Date();
                const timeDiff = currentDate - filterChangeDate;
                daysSinceFilterChange = timeDiff / (1000 * 3600 * 24); // days
              } else {
                daysSinceFilterChange = null;
              }

              // Classification
              if (
                (daysSinceFilterChange !== null &&
                  daysSinceFilterChange <= 90) ||
                totalEngineHours < 400
              ) {
                greenCount++;
              } else if (
                (daysSinceFilterChange > 90 && daysSinceFilterChange < 120) ||
                (totalEngineHours >= 400 && totalEngineHours < 500)
              ) {
                yellowCount++;
              } else if (
                daysSinceFilterChange >= 120 ||
                totalEngineHours >= 500
              ) {
                redCount++;
              }
            }

            companyOverdueData.push({
              companyName: company.name.substring(0, 10), // Abbreviate if needed
              greenCount,
              yellowCount,
              redCount,
            });
          }

          setOverdueFiltersData(companyOverdueData);
        } catch (error) {
          console.error("Error fetching overdue filters data:", error);
        }
      }

      fetchMetrics();
      fetchOverdueFiltersData();
    }
  }, [isAdmin, isOwner, isMechanic, currentUser]);

  if (!isAdmin && !isOwner && !isMechanic) {
    return <Container className="mt-5">Access Denied</Container>;
  }

  return (
    <Container className="mt-5">
      <h2>Admin Dashboard</h2>
      <Row className="mt-4 justify-content-center">
        {isAdmin && (
          <Col xs="auto" className="mb-3">
            <Card
              className="dashboard-card"
              onClick={() => navigate("/companies")}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Title>Companies</Card.Title>
                <Card.Text>{companyCount}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col xs="auto" className="mb-3">
          <Card
            className="dashboard-card"
            onClick={() => navigate("/equipment")}
            style={{ cursor: "pointer" }}
          >
            <Card.Body>
              <Card.Title>Equipment</Card.Title>
              <Card.Text>{equipmentCount}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-center">
        <Col xs={12}>
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Overdue Filters</Card.Title>
              <div style={{ width: "100%", height: "100%" }}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={overdueFiltersData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                  >
                    <XAxis dataKey="companyName" />
                    <YAxis allowDecimals={false} />
                    <Tooltip
                      cursor={{ fill: "rgba(0, 0, 0, 0.1)" }}
                      contentStyle={{
                        backgroundColor: "#2d2d2d",
                        borderRadius: "8px",
                        color: "#fff",
                        border: "1px solid #444",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
                      }}
                      formatter={(value, name) => {
                        // Use the friendly name from the 'name' prop
                        return [value, name];
                      }}
                    />
                    <Legend verticalAlign="top" height="auto" />
                    <CartesianGrid stroke="#ccc" />
                    <Bar
                      dataKey="greenCount"
                      stackId="a"
                      fill="green"
                      name="Good filters"
                      fillOpacity={1}
                    />
                    <Bar
                      dataKey="yellowCount"
                      stackId="a"
                      fill="yellow"
                      name="Filters expiring soon"
                      fillOpacity={1}
                    />
                    <Bar
                      dataKey="redCount"
                      stackId="a"
                      fill="red"
                      name="Expired filters"
                      fillOpacity={1}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminDashboard;
