import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import firebaseService from "../FirebaseService";
import { functions } from "../FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import firebaseAuthService from "../FirebaseAuthService";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc"; // Import Google icon
import "./LoginForm.css"; // Custom styles
import { Roles } from "../context/Roles";

function LoginForm() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      // Login the user
      const userContext = await firebaseAuthService.loginUser(
        credentials.email,
        credentials.password
      );

      console.log("user:", userContext);

      // Fetch user data from Firestore
      // const userRef = doc(firestore, "users", user.uid);
      // const userDoc = await getDoc(userRef);
      // const userData = userDoc.exists() ? userDoc.data() : {};
      const userData = await firebaseService.getUserById(userContext.user.uid);
      console.log("userData:", userData);
      const userRole = userData.role;

      if (!userRole) {
        alert(
          "Your account does not have a role assigned. Please contact support."
        );
        return;
      }

      if (userRole === Roles.DENIED || userRole === Roles.BLOCKED) {
        alert("Your account has been denied access. Please contact support.");
        await firebaseAuthService.logoutUser();
        return;
      }

      if (userRole === Roles.PENDING) {
        navigate("/verify-email");
      } else if (userRole === Roles.OPERATOR || userRole === Roles.MECHANIC) {
        navigate("/equipment");
      } else {
        // For other roles, navigate to dashboard
        navigate("/dashboard");
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function handleSendResetPasswordEmail() {
    if (!credentials.email) {
      alert("Please enter your email address.");
      return;
    }

    try {
      await firebaseAuthService.sendUserPasswordResetEmail(credentials.email);
      alert("Password reset email sent. Please check your inbox.");
    } catch (error) {
      alert(error.message);
    }
  }

  async function handleLoginWithGoogle() {
    try {
      const userCredential = await firebaseAuthService.loginWithGoogle();
      const user = userCredential.user;
      //const isNewUser = userCredential.additionalUserInfo.isNewUser;

      // Check if email is denied
      const checkIfEmailDenied = httpsCallable(functions, "checkIfEmailDenied");
      const result = await checkIfEmailDenied({ email: user.email });

      if (result.data.isDenied) {
        alert("This email has been denied access. Please contact support.");
        await firebaseAuthService.logoutUser(); // Sign out the user
        return;
      }

      // Check if user document exists
      const userRecord = await firebaseService.getUserById(user.uid);
      console.log(userRecord);

      if (!userRecord) {
        // Create user document in Firestore with 'pending' role
        const createUserRecord = httpsCallable(functions, "createUserRecord");
        await createUserRecord({
          email: user.email,
          displayName: user.displayName || "",
          phoneNumber: user.phoneNumber || "",
          providerId: user.providerId || "",
          providerData: user.providerData || [],
          createdOn: new Date().toISOString(),
        });

        // Optionally, send email verification (not necessary for Google accounts)
        // Navigate to a 'pending approval' or 'verification' page
        navigate("/verify-email");
      } else {
        console.log(userRecord);
        // User document exists; navigate based on role
        if (userRecord.role === Roles.DENIED) {
          alert("Your account has been denied access. Please contact support.");
          await firebaseAuthService.logoutUser(); // Sign out the user
          return;
        } else if (userRecord.role === Roles.PENDING) {
          navigate("/verify-email");
        } else if (userRecord.role === Roles.OPERATOR) {
          navigate("/equipment-history");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      alert(error.message);
    }
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setCredentials((prevData) => ({ ...prevData, [name]: value }));
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={6} lg={4}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={credentials.email}
                onChange={handleInputChange}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                required
                value={credentials.password}
                onChange={handleInputChange}
                placeholder="Password"
              />
            </Form.Group>
            <div className="button-box text-center">
              <Button variant="primary" type="submit" className="login-button">
                Login
              </Button>
              <Button
                variant="secondary"
                onClick={handleSendResetPasswordEmail}
                className="reset-button"
              >
                Reset Password
              </Button>
              <div className="google-login text-center mt-3">
                <Button
                  variant="light"
                  onClick={handleLoginWithGoogle}
                  className="google-button"
                >
                  <FcGoogle size={24} /> Login with Google
                </Button>
              </div>
            </div>
            <div className="text-center mt-3">
              <p>
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </p>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginForm;
