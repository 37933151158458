import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Container,
  Table,
  Button,
  Spinner,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import firebaseService from "../FirebaseService";
import { NotificationContext } from "../context/NotificationContext";
import CreatableSelect from "react-select/creatable";

import "../styles/ModalStyles.css";
import "../styles/TableStyles.css";
import "../styles/CardStyles.css";
import "../styles/ToastStyles.css";

import useWindowWidth from "../hooks/useWindowWidth";

function Users() {
  // Custom styles for CreatableSelect
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#212529",
      color: "#ffffff",
      border: "1px solid #ced4da",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#212529",
      color: "#ffffff",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    input: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adb5bd", // Light gray placeholder text
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#343a40" : "#212529",
      color: "#ffffff",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#343a40",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#ffffff",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#ced4da",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#ced4da",
    }),
  };

  // Use context functions
  const { showSuccess, showModalError } = useContext(NotificationContext);

  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [newUserData, setNewUserData] = useState({
    displayName: "",
    email: "",
    phoneNumber: "",
    mailingAddress: "",
    shippingAddress: "",
    role: "operator",
    companyId: "",
    companyName: "",
    sendInvitation: true,
  });
  const [currentUserToEdit, setCurrentUserToEdit] = useState(null);
  const [companies, setCompanies] = useState([]);

  const width = useWindowWidth(); // Get the window width
  const isMobile = width <= 576; // Determine if the screen is mobile-sized

  const isAdmin = currentUser?.role === "admin";
  const isOwner = currentUser?.role === "owner";
  const isMechanic = currentUser?.role === "mechanic";

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      let usersData;
      if (isAdmin) {
        usersData = await firebaseService.getAllUsers();
      } else {
        usersData = await firebaseService.getUsersByCompanyId(
          currentUser?.companyId
        );
      }
      setUsersList(usersData);
      setFilteredUsers(usersData);
    } catch (error) {
      showModalError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [isAdmin, currentUser, showModalError]);

  const fetchCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      let companiesData;
      if (isAdmin) {
        companiesData = await firebaseService.getAllCompanies();
      } else {
        companiesData = await firebaseService.getCompanies(currentUser);
      }
      setCompanies(companiesData);
    } catch (error) {
      showModalError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [isAdmin, currentUser, showModalError]);

  useEffect(() => {
    if (isAdmin || isOwner || isMechanic) {
      fetchUsers();
      fetchCompanies();
    }
  }, [isAdmin, isOwner, isMechanic, fetchUsers, fetchCompanies]);

  useEffect(() => {
    const filtered = usersList.filter(
      (user) =>
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.phoneNumber && user.phoneNumber.includes(searchTerm))
    );
    setFilteredUsers(filtered);
  }, [searchTerm, usersList]);

  function handleAddUser() {
    setShowAddUserModal(true);
    setNewUserData({
      displayName: "",
      email: "",
      phoneNumber: "",
      mailingAddress: "",
      shippingAddress: "",
      role: "operator",
      companyId: isAdmin ? "" : currentUser.companyId,
      companyName: "",
      sendInvitation: true,
    });
  }

  function handleAddUserClose() {
    setShowAddUserModal(false);
    setNewUserData({
      displayName: "",
      email: "",
      phoneNumber: "",
      mailingAddress: "",
      shippingAddress: "",
      role: "operator",
      companyId: isAdmin ? "" : currentUser.companyId,
      companyName: "",
      sendInvitation: true,
    });
  }

  async function handleAddUserSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Check if all required fields are filled
      const { displayName, email, role, companyId, companyName } = newUserData;

      let selectedCompanyId = companyId;

      if (isAdmin) {
        if (!displayName || !email || !role) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }

        if (role !== "admin" && !companyId && !companyName) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }

        // Proceed to check or create company only if role is not admin
        if (role !== "admin") {
          // Check if company exists, if not, create it
          if (!selectedCompanyId && companyName) {
            // Check if company with the same name already exists
            const existingCompany = companies.find(
              (company) =>
                company.name.toLowerCase() === companyName.toLowerCase()
            );

            if (existingCompany) {
              selectedCompanyId = existingCompany.id;
            } else {
              // Company doesn't exist, create it
              const newCompany = {
                name: companyName,
                createdAt: firebaseService.getServerTimestamp(),
              };
              const companyDocRef = await firebaseService.addCompany(
                newCompany
              );
              selectedCompanyId = companyDocRef.id;
              // Fetch the updated list of companies
              await fetchCompanies();
            }
          }
        }
      } else {
        // For non-admins
        if (!displayName || !email || !role) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }
        selectedCompanyId = currentUser.companyId;
        if (!selectedCompanyId) {
          showModalError("Your account does not have an associated company.");
          setIsLoading(false);
          return;
        }
      }

      // Call the Cloud Function to check if the email is denied
      const result = await firebaseService.checkIfEmailDenied(
        newUserData.email
      );

      if (result.isDenied) {
        setIsLoading(false);
        showModalError(
          "This email has been denied access. Please contact support."
        );
        return;
      }

      // Prepare the user data
      const userDataToAdd = {
        ...newUserData,
        companyId: selectedCompanyId,
        invited: true,
        invitedAt: firebaseService.getServerTimestamp(),
        // Do not include uid yet
      };

      // Remove companyName from user data
      delete userDataToAdd.companyName;

      // Create the user document
      await firebaseService.addUser(userDataToAdd);

      // If sendInvitation is true, call sendInvitationEmail function
      if (newUserData.sendInvitation) {
        try {
          await firebaseService.sendInvitationEmail(
            newUserData.email,
            newUserData.displayName
          );
          showSuccess("Invitation email sent successfully.");
        } catch (error) {
          showModalError("Failed to send invitation email.");
          console.error(error);
        }
      }

      handleAddUserClose();
      fetchUsers();
    } catch (error) {
      showModalError(error.message);
    }
    setIsLoading(false);
  }

  function handleNewUserChange(event) {
    const { name, value, type, checked } = event.target;
    setNewUserData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  // Handle company selection or creation
  function handleCompanyChange(newValue, actionMeta) {
    if (newValue) {
      if (newValue.__isNew__) {
        // New company
        setNewUserData((prevData) => ({
          ...prevData,
          companyId: "",
          companyName: newValue.value,
        }));
      } else {
        // Existing company
        setNewUserData((prevData) => ({
          ...prevData,
          companyId: newValue.value,
          companyName: "",
        }));
      }
    } else {
      // No selection
      setNewUserData((prevData) => ({
        ...prevData,
        companyId: "",
        companyName: "",
      }));
    }
  }

  // Prepare options for CreatableSelect
  const companyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));

  // Check if all required fields are filled
  const isFormValid = () => {
    const { displayName, email, role } = newUserData;

    if (isAdmin) {
      const { companyId, companyName } = newUserData;
      if (role === "admin") {
        // Company is not required for admin role
        return displayName && email && role;
      } else {
        // Company is required for other roles
        return displayName && email && role && (companyId || companyName);
      }
    } else {
      return displayName && email && role;
    }
  };

  function handleEditUser(user) {
    setCurrentUserToEdit(user);
    setNewUserData({
      ...user,
      companyName: "",
    });
    setShowEditUserModal(true);
  }

  function handleEditUserClose() {
    setShowEditUserModal(false);
    setCurrentUserToEdit(null);
  }

  async function handleEditUserSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const { displayName, email, role, companyId, companyName } = newUserData;
      let selectedCompanyId = companyId;

      if (isAdmin) {
        if (!displayName || !email || !role) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }

        if (role !== "admin" && !companyId && !companyName) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }

        if (role !== "admin") {
          if (!companyId && companyName) {
            // Check if company with the same name already exists
            const existingCompany = companies.find(
              (company) =>
                company.name.toLowerCase() === companyName.toLowerCase()
            );

            if (existingCompany) {
              selectedCompanyId = existingCompany.id;
            } else {
              // Company doesn't exist, create it
              const newCompany = {
                name: companyName,
                createdAt: firebaseService.getServerTimestamp(),
                // Add other company fields if necessary
              };
              const companyDocRef = await firebaseService.addCompany(
                newCompany
              );
              selectedCompanyId = companyDocRef.id;
              // Fetch the updated list of companies
              await fetchCompanies();
            }
          }
        }
      } else {
        if (!displayName || !email || !role) {
          showModalError("Please fill all required fields.");
          setIsLoading(false);
          return;
        }
        selectedCompanyId = currentUser.companyId;
        if (!selectedCompanyId) {
          showModalError("Your account does not have an associated company.");
          setIsLoading(false);
          return;
        }
      }

      if (currentUserToEdit) {
        const updatedUserData = {
          ...newUserData,
          companyId: selectedCompanyId,
        };
        delete updatedUserData.companyName;
        await firebaseService.updateUser(currentUserToEdit.id, updatedUserData);
        setShowEditUserModal(false);
        fetchUsers();
      }
      showSuccess("Updated successfully");
    } catch (error) {
      showModalError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDeleteUserConfirmed() {
    setShowDeleteConfirmModal(false);
    setIsLoading(true);
    try {
      await firebaseService.deleteUser(userIdToDelete);
      fetchUsers();
      showSuccess("Deleted successfully");
    } catch (error) {
      showModalError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  function handleUserChange(event) {
    const { name, value } = event.target;
    setNewUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  if (!isAdmin && !isOwner && !isMechanic) {
    return <Container className="mt-5">Access Denied</Container>;
  }

  function confirmDeleteUser(userId) {
    setUserIdToDelete(userId);
    setShowDeleteConfirmModal(true);
  }

  return (
    <Container className="mt-5">
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="light" />
        </div>
      )}
      <div className="table-container">
        <h2>Users</h2>
        {(isAdmin || isOwner) && (
          <Button variant="primary" onClick={handleAddUser} className="mb-3">
            Invite User
          </Button>
        )}
        {/* Search Box */}
        <InputGroup className="mb-3">
          <FormControl
            className="table-search-input"
            placeholder="Search by name, email, or phone number"
            aria-label="Search"
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {isMobile ? (
          <div>
            {filteredUsers.map((userItem) => (
              <Card key={userItem.id} className="mb-3 card-dark">
                <Card.Body>
                  <Card.Title>{userItem.displayName}</Card.Title>
                  <Card.Text>
                    <strong>Email:</strong> {userItem.email}
                    <br />
                    <strong>Phone Number:</strong> {userItem.phoneNumber}
                    <br />
                    <strong>Role:</strong> {userItem.role}
                    <br />
                    <strong>Company:</strong>{" "}
                    {companies.find((c) => c.id === userItem.companyId)?.name ||
                      ""}
                  </Card.Text>
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleEditUser(userItem)}
                  >
                    Edit
                  </Button>{" "}
                  {userItem.id !== currentUser.id && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => confirmDeleteUser(userItem.id)}
                    >
                      Delete
                    </Button>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <Table striped bordered hover variant="dark" responsive>
            <thead>
              <tr>
                <th>Display Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Role</th>
                <th>Company</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((userItem) => (
                <tr key={userItem.id}>
                  <td>{userItem.displayName}</td>
                  <td>{userItem.email}</td>
                  <td>{userItem.phoneNumber}</td>
                  <td>{userItem.role}</td>
                  <td>
                    {companies.find((c) => c.id === userItem.companyId)?.name ||
                      ""}
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => handleEditUser(userItem)}
                    >
                      Edit
                    </Button>{" "}
                    {userItem.id !== currentUser.id && (
                      <Button
                        variant="danger"
                        onClick={() => confirmDeleteUser(userItem.id)}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      {/* Add User Modal */}
      <Modal show={showAddUserModal} onHide={handleAddUserClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddUserSubmit}>
          <Modal.Body>
            {/* Send Invitation Email Checkbox */}
            <Form.Group controlId="formSendInvitation">
              <div className="custom-checkbox-container">
                <Form.Check
                  type="checkbox"
                  label="Send Invitation Email"
                  name="sendInvitation"
                  checked={newUserData.sendInvitation}
                  onChange={handleNewUserChange}
                />
              </div>
            </Form.Group>

            <Form.Group controlId="formDisplayName">
              <Form.Label>
                Display Name<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="displayName"
                value={newUserData.displayName}
                onChange={handleNewUserChange}
                required
                placeholder="Enter display name"
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>
                Email<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUserData.email}
                onChange={handleNewUserChange}
                required
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={newUserData.phoneNumber}
                onChange={handleNewUserChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <Form.Group controlId="formMailingAddress">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={newUserData.mailingAddress}
                onChange={handleNewUserChange}
                placeholder="Enter mailing address"
              />
            </Form.Group>
            <Form.Group controlId="formShippingAddress">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={newUserData.shippingAddress}
                onChange={handleNewUserChange}
                placeholder="Enter shipping address"
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>
                Role<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={newUserData.role}
                onChange={handleNewUserChange}
                required
              >
                {isAdmin && <option value="admin">Admin</option>}
                {isAdmin && <option value="owner">Owner</option>}
                <option value="operator">Operator</option>
                {(isAdmin || isOwner || isMechanic) && (
                  <option value="mechanic">Mechanic</option>
                )}
                {isAdmin && <option value="billing">Billing</option>}
              </Form.Control>
            </Form.Group>
            {/* Company Field */}
            {isAdmin && (
              <Form.Group controlId="formCompanyId">
                <Form.Label>
                  Company
                  {newUserData.role !== "admin" && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                </Form.Label>
                <CreatableSelect
                  isClearable
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  value={
                    newUserData.companyId
                      ? companyOptions.find(
                          (option) => option.value === newUserData.companyId
                        )
                      : newUserData.companyName
                      ? {
                          label: newUserData.companyName,
                          value: newUserData.companyName,
                        }
                      : null
                  }
                  placeholder="Select or type to add a company"
                  styles={customStyles}
                  isValidNewOption={() => true} // Always allow new options
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddUserClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={!isFormValid()}>
              Invite User
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit User Modal */}
      <Modal show={showEditUserModal} onHide={handleEditUserClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditUserSubmit}>
          <Modal.Body>
            <Form.Group controlId="editDisplayName">
              <Form.Label>
                Display Name<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="displayName"
                value={newUserData.displayName}
                onChange={handleUserChange}
                required
                placeholder="Enter display name"
              />
            </Form.Group>
            <Form.Group controlId="editEmail">
              <Form.Label>
                Email<span style={{ color: "red" }}> *</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUserData.email}
                onChange={handleUserChange}
                required
                placeholder="Enter email"
                disabled={currentUserToEdit?.invited && !currentUserToEdit?.uid}
              />
            </Form.Group>
            <Form.Group controlId="editPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={newUserData.phoneNumber}
                onChange={handleUserChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <Form.Group controlId="editMailingAddress">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={newUserData.mailingAddress}
                onChange={handleUserChange}
                placeholder="Enter mailing address"
              />
            </Form.Group>
            <Form.Group controlId="editShippingAddress">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={newUserData.shippingAddress}
                onChange={handleUserChange}
                placeholder="Enter shipping address"
              />
            </Form.Group>
            {/* Role Field */}
            {currentUserToEdit?.id !== currentUser.id ? (
              <Form.Group controlId="editRole">
                <Form.Label>
                  Role<span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  value={newUserData.role}
                  onChange={handleUserChange}
                  required
                >
                  {isAdmin && <option value="admin">Admin</option>}
                  {isAdmin && <option value="owner">Owner</option>}
                  <option value="operator">Operator</option>
                  {(isAdmin || isOwner || isMechanic) && (
                    <option value="mechanic">Mechanic</option>
                  )}
                  {isAdmin && <option value="billing">Billing</option>}
                </Form.Control>
              </Form.Group>
            ) : (
              <Form.Group controlId="editRole">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  value={newUserData.role}
                  disabled
                  readOnly
                />
              </Form.Group>
            )}
            {/* Company Field */}
            {isAdmin && currentUserToEdit?.id !== currentUser.id ? (
              <Form.Group controlId="editCompanyId">
                <Form.Label>
                  Company
                  {newUserData.role !== "admin" && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                </Form.Label>
                <CreatableSelect
                  isClearable
                  onChange={handleCompanyChange}
                  options={companyOptions}
                  value={
                    newUserData.companyId
                      ? companyOptions.find(
                          (option) => option.value === newUserData.companyId
                        )
                      : newUserData.companyName
                      ? {
                          label: newUserData.companyName,
                          value: newUserData.companyName,
                        }
                      : null
                  }
                  placeholder="Select or type to add a company"
                  styles={customStyles}
                  isValidNewOption={() => true} // Always allow new options
                />
              </Form.Group>
            ) : (
              <Form.Group controlId="editCompanyId">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    companies.find((c) => c.id === newUserData.companyId)
                      ?.name || ""
                  }
                  disabled
                  readOnly
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditUserClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUserConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Users;
