import React, { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { NotificationContext } from "../context/NotificationContext";

const ErrorModal = () => {
  const { modalError, hideModalError } = useContext(NotificationContext);

  return (
    <Modal show={!!modalError} onHide={hideModalError}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalError}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModalError}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
