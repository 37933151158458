import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";

/**
 * Converts a Firestore Timestamp to a Luxon DateTime object.
 * @param {Timestamp} timestamp - Firestore Timestamp object.
 * @returns {DateTime} - Luxon DateTime object.
 */
export function fromFirestoreTimestamp(timestamp) {
  if (!timestamp) return null;
  if (timestamp instanceof Timestamp) {
    return DateTime.fromJSDate(timestamp.toDate());
  }
  // Handle other formats if necessary
  return DateTime.fromISO(new Date(timestamp).toISOString());
}

/**
 * Formats a Luxon DateTime object to a human-readable string.
 * @param {DateTime} dateTime - Luxon DateTime object.
 * @returns {string} - Formatted date string (e.g., 'Jan 1, 2024').
 */
export function formatDateForDisplay(dateTime) {
  if (!dateTime) return "N/A";
  return dateTime.toLocaleString(DateTime.DATE_MED);
}

/**
 * Formats a Luxon DateTime object for input fields (YYYY-MM-DD).
 * @param {DateTime} dateTime - Luxon DateTime object.
 * @returns {string} - Formatted date string for input.
 */
export function formatDateForInput(dateTime) {
  if (!dateTime) return "";
  return dateTime.toISODate();
}

/**
 * Parses a date string from input (YYYY-MM-DD) to a Luxon DateTime object.
 * @param {string} dateString - Date string from input.
 * @returns {DateTime} - Luxon DateTime object.
 */
export function parseDateFromInput(dateString) {
  return DateTime.fromISO(dateString);
}

/**
 * Validates if a date string is in a correct format.
 * @param {string} dateString - Date string to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
export function isValidDate(dateString) {
  const dateTime = DateTime.fromISO(dateString);
  return dateTime.isValid;
}

/**
 * Converts a date input to Firestore Timestamp.
 * @param {string} dateString - Date string from input.
 * @returns {Timestamp} - Firestore Timestamp object.
 */
export function toFirestoreTimestamp(input) {
  if (!input) return null;

  // If already a Date object:
  if (input instanceof Date) {
    return Timestamp.fromDate(input);
  }

  // If input is a string, parse using Luxon:
  const dateTime = DateTime.fromISO(input);
  if (!dateTime.isValid) return null;

  return Timestamp.fromDate(dateTime.toJSDate());
}
