import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Card,
  Pagination,
} from "react-bootstrap";
import firebaseService from "../FirebaseService";
import { useAuth } from "../context/AuthContext";
import useWindowWidth from "../hooks/useWindowWidth";

import "../styles/ModalStyles.css";
import "../styles/TableStyles.css";
import "../styles/CardStyles.css";

function EquipmentHistory() {
  const width = useWindowWidth();
  const isMobile = width <= 576;

  const { currentUser } = useAuth();
  const [equipmentHistoryList, setEquipmentHistoryList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [filteredEquipmentHistory, setFilteredEquipmentHistory] = useState([]);
  const [showAddHistoryModal, setShowAddHistoryModal] = useState(false);
  const [showEditHistoryModal, setShowEditHistoryModal] = useState(false);
  const [showDeleteHistoryModal, setShowDeleteHistoryModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEquipmentFilter, setSelectedEquipmentFilter] = useState("");
  const [sortField, setSortField] = useState("recordedOn");
  const [sortDirection, setSortDirection] = useState("desc");
  const [newHistoryData, setNewHistoryData] = useState({
    equipmentId: "",
    currentMileage: "",
    gallonsOfFuelAdded: "",
    oilAddedAmount: "",
    filterChanged: false,
    recordedOn: "",
  });
  const [currentHistory, setCurrentHistory] = useState(null);
  const [historyToDelete, setHistoryToDelete] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch equipment accessible to the user
        const equipmentData = await firebaseService.getEquipment(currentUser);
        setEquipmentList(equipmentData);

        // Fetch equipment history accessible to the user
        const params = {
          sortField,
          sortDirection,
          limit: 20,
        };
        const historyData = await firebaseService.getEquipmentHistory(
          currentUser,
          params
        );

        setEquipmentHistoryList(historyData.data);
        setFilteredEquipmentHistory(historyData.data);
      } catch (error) {
        alert(error.message);
      }
    }
    fetchData();
  }, [currentUser, sortField, sortDirection]);

  useEffect(() => {
    let filtered = equipmentHistoryList;

    // Filter by equipment
    if (selectedEquipmentFilter) {
      filtered = filtered.filter(
        (item) => item.equipmentId === selectedEquipmentFilter
      );
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item.currentMileage.includes(searchTerm)
      );
    }

    setFilteredEquipmentHistory(filtered);
  }, [searchTerm, equipmentHistoryList, selectedEquipmentFilter]);

  function handleAddHistory() {
    setShowAddHistoryModal(true);
    setNewHistoryData({
      equipmentId: equipmentList.length === 1 ? equipmentList[0].id : "",
      currentMileage: "",
      gallonsOfFuelAdded: "",
      oilAddedAmount: "",
      filterChanged: false,
      recordedOn: new Date().toISOString().substr(0, 10),
    });
  }

  function handleAddHistoryClose() {
    setShowAddHistoryModal(false);
    setNewHistoryData({
      equipmentId: "",
      currentMileage: "",
      gallonsOfFuelAdded: "",
      oilAddedAmount: "",
      filterChanged: false,
      recordedOn: "",
    });
  }

  async function handleAddHistorySubmit(event) {
    event.preventDefault();

    try {
      const historyData = {
        ...newHistoryData,
        createdBy: currentUser.uid,
      };
      await firebaseService.addEquipmentHistory(historyData);

      handleAddHistoryClose();
      // Refresh equipment history list
      const updatedHistory = await firebaseService.getEquipmentHistory({
        sortField,
        sortDirection,
      });
      setEquipmentHistoryList(updatedHistory.data);
    } catch (error) {
      alert(error.message);
    }
  }

  function handleNewHistoryChange(event) {
    const { name, value, type, checked } = event.target;
    setNewHistoryData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  function handleEditHistory(history) {
    setCurrentHistory(history);
    const historyData = {
      ...history,
      recordedOn: history.recordedOn
        ? history.recordedOn.toDate().toISOString().substr(0, 10)
        : "",
    };
    setNewHistoryData(historyData);
    setShowEditHistoryModal(true);
  }

  function handleEditHistoryClose() {
    setShowEditHistoryModal(false);
    setCurrentHistory(null);
  }

  async function handleEditHistorySubmit(event) {
    event.preventDefault();

    try {
      await firebaseService.updateEquipmentHistory(
        currentHistory.id,
        newHistoryData
      );

      handleEditHistoryClose();
      // Refresh equipment history list
      const updatedHistory = await firebaseService.getEquipmentHistory({
        sortField,
        sortDirection,
      });
      setEquipmentHistoryList(updatedHistory.data);
    } catch (error) {
      alert(error.message);
    }
  }

  function handleHistoryChange(event) {
    const { name, value, type, checked } = event.target;
    setNewHistoryData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  function handleDeleteHistory(history) {
    setHistoryToDelete(history);
    setShowDeleteHistoryModal(true);
  }

  function handleDeleteHistoryClose() {
    setShowDeleteHistoryModal(false);
    setHistoryToDelete(null);
  }

  async function handleConfirmDeleteHistory() {
    try {
      if (historyToDelete) {
        await firebaseService.deleteEquipmentHistory(historyToDelete.id);

        setShowDeleteHistoryModal(false);
        setHistoryToDelete(null);
        // Refresh equipment history list
        const updatedHistory = await firebaseService.getEquipmentHistory({
          sortField,
          sortDirection,
        });
        setEquipmentHistoryList(updatedHistory.data);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  // Pagination
  const totalPages = Math.ceil(filteredEquipmentHistory.length / itemsPerPage);
  const paginatedHistory = filteredEquipmentHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function renderPagination() {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  }

  function handleSort(field) {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  }

  // Permission checks
  function canEdit(history) {
    // Logic to determine if the user can edit the history entry
    // This can be simplified if you centralize permission logic
    const isAdmin = currentUser?.role === "admin";
    const isOwnerOrMechanic =
      currentUser?.role === "owner" || currentUser?.role === "mechanic";
    const isOperator = currentUser?.role === "operator";

    if (isAdmin || isOwnerOrMechanic) {
      return true;
    } else if (isOperator && history.createdBy === currentUser.uid) {
      return true;
    }
    return false;
  }

  function canDelete(history) {
    return canEdit(history);
  }

  return (
    <Container className="mt-5">
      <div className="table-container">
        <h2>Equipment History</h2>
        <Button variant="primary" onClick={handleAddHistory} className="mb-3">
          Add Entry
        </Button>
        {/* Filters */}
        <InputGroup className="mb-3">
          <Form.Select
            value={selectedEquipmentFilter}
            onChange={(e) => setSelectedEquipmentFilter(e.target.value)}
          >
            <option value="">All Equipment</option>
            {equipmentList.map((equipment) => (
              <option key={equipment.id} value={equipment.id}>
                {equipment.equipmentName || equipment.vin}
              </option>
            ))}
          </Form.Select>
          <FormControl
            placeholder="Search by Current Mileage"
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {isMobile ? (
          <div>
            {paginatedHistory.map((history) => (
              <Card key={history.id} className="mb-3 card-dark">
                <Card.Body>
                  <Card.Title>
                    {
                      equipmentList.find((e) => e.id === history.equipmentId)
                        ?.equipmentName
                    }
                  </Card.Title>
                  <Card.Text>
                    <strong>Current Mileage:</strong> {history.currentMileage}
                    <br />
                    <strong>Gallons of Fuel Added:</strong>{" "}
                    {history.gallonsOfFuelAdded}
                    <br />
                    <strong>Oil Added Amount:</strong> {history.oilAddedAmount}
                    <br />
                    <strong>Filter Changed:</strong>{" "}
                    {history.filterChanged ? "Yes" : "No"}
                    <br />
                    <strong>Recorded On:</strong>{" "}
                    {history.recordedOn.toDate().toLocaleDateString()}
                  </Card.Text>
                  {canEdit(history) && (
                    <>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEditHistory(history)}
                      >
                        Edit
                      </Button>{" "}
                    </>
                  )}
                  {canDelete(history) && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteHistory(history)}
                    >
                      Delete
                    </Button>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <Table striped bordered hover variant="dark" responsive>
                <thead>
                  <tr>
                    <th onClick={() => handleSort("equipmentId")}>
                      Equipment{" "}
                      {sortField === "equipmentId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("currentMileage")}>
                      Current Mileage{" "}
                      {sortField === "currentMileage" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("gallonsOfFuelAdded")}>
                      Gallons of Fuel Added{" "}
                      {sortField === "gallonsOfFuelAdded" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("oilAddedAmount")}>
                      Oil Added Amount{" "}
                      {sortField === "oilAddedAmount" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("filterChanged")}>
                      Filter Changed{" "}
                      {sortField === "filterChanged" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("recordedOn")}>
                      Recorded On{" "}
                      {sortField === "recordedOn" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedHistory.map((history) => (
                    <tr key={history.id}>
                      <td>
                        {
                          equipmentList.find(
                            (e) => e.id === history.equipmentId
                          )?.equipmentName
                        }
                      </td>
                      <td>{history.currentMileage}</td>
                      <td>{history.gallonsOfFuelAdded}</td>
                      <td>{history.oilAddedAmount}</td>
                      <td>{history.filterChanged ? "Yes" : "No"}</td>
                      <td>
                        {history.recordedOn.toDate().toLocaleDateString()}
                      </td>
                      <td>
                        {canEdit(history) && (
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => handleEditHistory(history)}
                          >
                            Edit
                          </Button>
                        )}{" "}
                        {canDelete(history) && (
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteHistory(history)}
                          >
                            Delete
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {renderPagination()}
          </>
        )}
      </div>

      {/* Add Equipment History Modal */}
      <Modal show={showAddHistoryModal} onHide={handleAddHistoryClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Equipment History</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddHistorySubmit}>
          <Modal.Body>
            <Form.Group controlId="formEquipmentId">
              <Form.Label>Equipment</Form.Label>
              <Form.Control
                as="select"
                name="equipmentId"
                value={newHistoryData.equipmentId}
                onChange={handleNewHistoryChange}
                required
              >
                <option value="">Select Equipment</option>
                {equipmentList.map((equipment) => (
                  <option key={equipment.id} value={equipment.id}>
                    {equipment.equipmentName || equipment.vin}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCurrentMileage">
              <Form.Label>Current Mileage</Form.Label>
              <Form.Control
                type="text"
                name="currentMileage"
                value={newHistoryData.currentMileage}
                onChange={handleNewHistoryChange}
                required
                placeholder="Enter current mileage"
              />
            </Form.Group>
            <Form.Group controlId="formGallonsOfFuelAdded">
              <Form.Label>Gallons of Fuel Added</Form.Label>
              <Form.Control
                type="number"
                name="gallonsOfFuelAdded"
                value={newHistoryData.gallonsOfFuelAdded}
                onChange={handleNewHistoryChange}
                placeholder="Enter gallons of fuel added"
              />
            </Form.Group>
            <Form.Group controlId="formOilAddedAmount">
              <Form.Label>Oil Added Amount</Form.Label>
              <Form.Control
                type="number"
                name="oilAddedAmount"
                value={newHistoryData.oilAddedAmount}
                onChange={handleNewHistoryChange}
                placeholder="Enter oil added amount"
              />
            </Form.Group>
            <Form.Group controlId="formFilterChanged">
              <Form.Check
                type="checkbox"
                name="filterChanged"
                label="Filter Changed"
                checked={newHistoryData.filterChanged}
                onChange={handleNewHistoryChange}
              />
            </Form.Group>
            <Form.Group controlId="formRecordedOn">
              <Form.Label>Recorded On</Form.Label>
              <Form.Control
                type="date"
                name="recordedOn"
                value={newHistoryData.recordedOn}
                onChange={handleNewHistoryChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddHistoryClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add Entry
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Equipment History Modal */}
      <Modal show={showEditHistoryModal} onHide={handleEditHistoryClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Equipment History</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditHistorySubmit}>
          <Modal.Body>
            <Form.Group controlId="editEquipmentId">
              <Form.Label>Equipment</Form.Label>
              <Form.Control
                as="select"
                name="equipmentId"
                value={newHistoryData.equipmentId}
                onChange={handleHistoryChange}
                required
              >
                <option value="">Select Equipment</option>
                {equipmentList.map((equipment) => (
                  <option key={equipment.id} value={equipment.id}>
                    {equipment.equipmentName || equipment.vin}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="editCurrentMileage">
              <Form.Label>Current Mileage</Form.Label>
              <Form.Control
                type="text"
                name="currentMileage"
                value={newHistoryData.currentMileage}
                onChange={handleHistoryChange}
                required
                placeholder="Enter current mileage"
              />
            </Form.Group>
            <Form.Group controlId="editGallonsOfFuelAdded">
              <Form.Label>Gallons of Fuel Added</Form.Label>
              <Form.Control
                type="number"
                name="gallonsOfFuelAdded"
                value={newHistoryData.gallonsOfFuelAdded}
                onChange={handleHistoryChange}
                placeholder="Enter gallons of fuel added"
              />
            </Form.Group>
            <Form.Group controlId="editOilAddedAmount">
              <Form.Label>Oil Added Amount</Form.Label>
              <Form.Control
                type="number"
                name="oilAddedAmount"
                value={newHistoryData.oilAddedAmount}
                onChange={handleHistoryChange}
                placeholder="Enter oil added amount"
              />
            </Form.Group>
            <Form.Group controlId="editFilterChanged">
              <Form.Check
                type="checkbox"
                name="filterChanged"
                label="Filter Changed"
                checked={newHistoryData.filterChanged}
                onChange={handleHistoryChange}
              />
            </Form.Group>
            <Form.Group controlId="editRecordedOn">
              <Form.Label>Recorded On</Form.Label>
              <Form.Control
                type="date"
                name="recordedOn"
                value={newHistoryData.recordedOn}
                onChange={handleHistoryChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditHistoryClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Equipment History Confirmation Modal */}
      <Modal show={showDeleteHistoryModal} onHide={handleDeleteHistoryClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this entry? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteHistoryClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteHistory}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default EquipmentHistory;
