import React from "react";
import { Container } from "react-bootstrap";

function TermsOfService() {
  return (
    <Container className="mt-5">
      <h2>Terms of Service</h2>
      {/* Add terms content here */}
    </Container>
  );
}

export default TermsOfService;
