// Services
import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Use AuthProvider

// Pages
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Companies from "./pages/Companies";
import Account from "./pages/Account";
import VerifyEmail from "./pages/VerifyEmail";
import PendingApproval from "./pages/PendingApproval";
import AdminApprovals from "./pages/AdminApprovals";
import AccessDenied from "./pages/AccessDenied";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoginForm from "./components/LoginForm";
import SignUpForm from "./components/SignUpForm";
import AdminDashboard from "./components/AdminDashboard";
import Users from "./components/Users";
import Equipment from "./components/Equipment";
import EquipmentAll from "./components/EquipmentAll";
import EquipmentHistory from "./components/EquipmentHistory";

// Messaging
import ErrorBoundary from "./context/ErrorBoundary";
import { NotificationProvider } from "./context/NotificationContext";
import ToastNotifications from "./components/ToastNotifications";
import ErrorModal from "./components/ErrorModal";

// Route Protection
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";

// Styles
import "./App.module.css";

function App() {
  return (
    <ErrorBoundary>
      <NotificationProvider>
        {/* Toast and Modal Components */}
        <ToastNotifications />
        <ErrorModal />
        <AuthProvider>
          <div className="app-container">
            <Header />
            <main className="content">
              <Routes>
                {/* Public Routes */}
                <Route
                  path="/"
                  element={
                    <PublicRoute>
                      <LoginForm />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <LoginForm />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <PublicRoute>
                      <SignUpForm />
                    </PublicRoute>
                  }
                />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/pending-approval" element={<PendingApproval />} />
                <Route path="/access-denied" element={<AccessDenied />} />
                {/* Protected Routes */}
                <Route
                  path="/companies"
                  element={
                    <ProtectedRoute>
                      <Companies />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute
                      requiredRole={["admin", "owner", "mechanic"]}
                    >
                      <AdminDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute
                      requiredRole={["admin", "owner", "mechanic"]}
                    >
                      <Users />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/pending-approval"
                  element={
                    <ProtectedRoute>
                      <PendingApproval />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/approvals"
                  element={
                    <ProtectedRoute requiredRole="admin">
                      <AdminApprovals />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/equipment"
                  element={
                    <ProtectedRoute>
                      <Equipment />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/equipment-report"
                  element={
                    <ProtectedRoute requiredRole="admin">
                      <EquipmentAll />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/equipment-history"
                  element={
                    <ProtectedRoute>
                      <EquipmentHistory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/account"
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                {/* Other routes... */}
              </Routes>
              <br />
            </main>
            <Footer />
          </div>
        </AuthProvider>
      </NotificationProvider>
    </ErrorBoundary>
  );
}

export default App;
