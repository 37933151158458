import React from "react";
import { Container } from "react-bootstrap";

function PendingApproval() {
  return (
    <Container className="mt-5 text-center">
      <h2>Your Account is Pending Approval</h2>
      <p>
        Thank you for verifying your email. Your account is currently pending
        approval by an administrator. You will receive an email notification
        once your account has been approved.
      </p>
    </Container>
  );
}

export default PendingApproval;
