import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, Modal } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import firebaseService from "../FirebaseService";

import "../styles/ModalStyles.css";
import "../styles/TableStyles.css";
import "../styles/CardStyles.css";

function AdminApprovals() {
  const { currentUser } = useAuth();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [companies, setCompanies] = useState([]);

  const isAdmin = currentUser?.role === "admin";

  useEffect(() => {
    if (isAdmin) {
      fetchPendingUsers();
      fetchCompanies();
    }
  }, [isAdmin]);

  async function fetchPendingUsers() {
    try {
      const allUsers = await firebaseService.getAllUsers();
      const pending = allUsers.filter((user) => user.role === "pending");
      setPendingUsers(pending);
    } catch (error) {
      console.error("Error fetching pending users:", error);
    }
  }

  async function fetchCompanies() {
    try {
      const companiesData = await firebaseService.getAllCompanies();
      setCompanies(companiesData);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  }

  async function handleDeny(userId) {
    try {
      await firebaseService.updateUser(userId, {
        role: "denied",
        deniedBy: currentUser.uid,
        deniedAt: firebaseService.getServerTimestamp(),
      });
      setPendingUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
    } catch (error) {
      console.error("Error denying user:", error);
    }
  }

  function handleEditUser(pendingUser) {
    setSelectedUser(pendingUser);
    setUserData({
      displayName: pendingUser.displayName || "",
      phoneNumber: pendingUser.phoneNumber || "",
      mailingAddress: pendingUser.mailingAddress || "",
      shippingAddress: pendingUser.shippingAddress || "",
      role: "operator", // Default role after approval
      companyId: "", // Or fetch available companies
    });
    setShowUserModal(true);
  }

  function handleCloseUserModal() {
    setShowUserModal(false);
    setSelectedUser(null);
    setUserData({});
  }

  function handleUserDataChange(event) {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  async function handleSaveUserDetails(event) {
    event.preventDefault();

    try {
      await firebaseService.updateUser(selectedUser.id, {
        ...userData,
        approvedBy: currentUser.uid,
        approvedAt: firebaseService.getServerTimestamp(),
      });

      setPendingUsers((prevUsers) =>
        prevUsers.filter((u) => u.id !== selectedUser.id)
      );
      handleCloseUserModal();
    } catch (error) {
      console.error("Error approving user:", error);
    }
  }

  if (!isAdmin) {
    return <Container className="mt-5">Access Denied</Container>;
  }

  return (
    <Container className="mt-5">
      <div className="table-container">
        <h2>Pending User Approvals</h2>
        <Table striped bordered hover variant="dark" responsive>
          <thead>
            <tr>
              <th>Email</th>
              <th>Display Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pendingUsers.map((pendingUser) => (
              <tr key={pendingUser.id}>
                <td>{pendingUser.email}</td>
                <td>{pendingUser.displayName}</td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => handleEditUser(pendingUser)}
                  >
                    Review
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => handleDeny(pendingUser.id)}
                  >
                    Deny
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>Review User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSaveUserDetails}>
          <Modal.Body>
            <Form.Group controlId="formDisplayName">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                name="displayName"
                value={userData.displayName}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={userData.phoneNumber}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="formMailingAddress">
              <Form.Label>Mailing Address</Form.Label>
              <Form.Control
                type="text"
                name="mailingAddress"
                value={userData.mailingAddress}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="formShippingAddress">
              <Form.Label>Shipping Address</Form.Label>
              <Form.Control
                type="text"
                name="shippingAddress"
                value={userData.shippingAddress}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                name="role"
                value={userData.role}
                onChange={handleUserDataChange}
              >
                <option value="admin">Admin</option>
                <option value="owner">Owner</option>
                <option value="operator">Operator</option>
                <option value="mechanic">Mechanic</option>
                <option value="billing">Billing</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formCompanyId">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={userData.companyId}
                onChange={handleUserDataChange}
              >
                <option value="">Select a company</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUserModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Approve User
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default AdminApprovals;
