export function formatNumberWithCommas(value) {
  try {
    if (value === null || value === undefined || value === "") return "";
    const numericValue = value.toString().replace(/,/g, "");
    if (isNaN(numericValue)) return value;
    return parseInt(numericValue, 10).toLocaleString();
  } catch (error) {
    console.log(error.message);
    return 0;
  }
}

export function removeCommas(value) {
  try {
    if (value === null || value === undefined) return "";
    // Convert the value to a string in case it's a number or another type
    const stringValue = value.toString();
    return stringValue.replace(/,/g, "");
  } catch (error) {
    console.log(error.message);
    return "0";
  }
}
