import React from "react";
import { Container } from "react-bootstrap";

function VerifyEmail() {
  return (
    <Container className="mt-5 text-center">
      <h2>Email Verification Required</h2>
      <p>
        Thank you for signing up. A verification email has been sent to your
        email address. Please check your inbox and verify your email to
        continue.
      </p>
    </Container>
  );
}

export default VerifyEmail;
