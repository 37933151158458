import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth } from "./FirebaseConfig";

const registerUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const loginUser = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const logoutUser = () => {
  return signOut(auth);
};

const sendUserPasswordResetEmail = (email) => {
  return sendPasswordResetEmail(auth, email);
};

const loginWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

const sendEmailVerificationToUser = (user) => {
  return sendEmailVerification(user);
};

const updateUserProfile = (user, itemsToUpdate) => {
  return updateProfile(user, itemsToUpdate);
};

const firebaseAuthService = {
  registerUser,
  loginUser,
  logoutUser,
  sendUserPasswordResetEmail,
  loginWithGoogle,
  sendEmailVerificationToUser,
  updateUserProfile,
};

export default firebaseAuthService;
