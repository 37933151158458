import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"; // Import social media icons
import "./Footer.css"; // Custom CSS styles

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-line"></div> {/* Horizontal line */}
      <Container>
        <Row className="align-items-center justify-content-between">
          {/* Column 1 - Logo */}
          <Col xs={12} md={6} className="footer-left mb-3 mb-md-0">
            <a href="/">
              <img
                src="/assets/logo_footer_edited.webp"
                alt="Company Logo"
                className="footer-logo"
              />
            </a>
          </Col>

          {/* Grouped Column 2 - Social Media, Contact, and Location */}
          <Col xs={12} md={6} className="footer-right-group">
            <Row className="justify-content-end">
              {/* Social Media Column */}
              <Col
                xs={12}
                md="auto"
                className="footer-social text-center mb-3 mb-md-0"
              >
                <div className="footer-social-media">
                  <p className="social-title">Social Media</p>
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/61562604297217"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FaFacebookSquare size={30} />
                    </a>
                    <a
                      href="https://www.instagram.com/pureflowsolutionsllc"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social-icon"
                    >
                      <FaInstagram size={30} />
                    </a>
                  </div>
                </div>
              </Col>

              {/* Contact Column */}
              <Col xs={12} md="auto" className="footer-contact mb-3 mb-md-0">
                <div className="contact-info">
                  <p>(602) 769-7266</p>
                  <p>pureflowsolutionsllc@gmail.com</p>
                </div>
              </Col>

              {/* Location Column */}
              <Col xs={12} md="auto" className="footer-location">
                <div>
                  <p>United States</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
