import React from "react";
import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

function PublicRoute({ children }) {
  const { currentUser } = useAuth();

  if (currentUser) {
    // User is logged in, redirect to dashboard
    return <Navigate to="/dashboard" replace />;
  }

  // User is not logged in, render the public component
  return children;
}

export default PublicRoute;
