import React from "react";
import { Container } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <Container className="mt-5">
      <h2>Privacy Policy</h2>
      {/* Add privacy policy content here */}
    </Container>
  );
}

export default PrivacyPolicy;
