import React, { createContext, useEffect, useState, useContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../FirebaseConfig";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const auth = getAuth();

    // auth.currentUser
    //   .getIdToken(/* forceRefresh */ true)
    //   .then(function (idToken) {
    //     // Send the token to Postman
    //     console.log(idToken);
    //   })
    //   .catch(function (error) {
    //     // Handle error
    //   });

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user data from Firestore
        const userRef = doc(firestore, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : {};

        // Merge Firebase Auth user and Firestore user data
        const mergedUser = { ...user, ...userData };

        // After merging user data
        console.log("Merged User:", mergedUser);
        console.log("User Role:", mergedUser.role);

        setCurrentUser(mergedUser);
      } else {
        setCurrentUser(null);
      }
      setIsLoading(false);
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook for easy access to AuthContext
export function useAuth() {
  return useContext(AuthContext);
}
