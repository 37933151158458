import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Table,
  Form,
  InputGroup,
  FormControl,
  Pagination,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import firebaseService from "../FirebaseService";
import { formatNumberWithCommas } from "../utils/numberUtils";
import {
  fromFirestoreTimestamp,
  formatDateForDisplay,
} from "../utils/dateUtils";
import * as XLSX from "xlsx";

function EquipmentAll() {
  const { currentUser } = useAuth();
  const { showModalError } = useContext(NotificationContext);

  const isAdmin = currentUser?.role === "admin";

  const [companies, setCompanies] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const [fuelLogsMap, setFuelLogsMap] = useState({});
  const [serviceLogsMap, setServiceLogsMap] = useState({});
  const [companiesMap, setCompaniesMap] = useState({});
  const [filterTypeMap, setFilterTypeMap] = useState({});

  // New state for equipment metrics
  const [metricsMap, setMetricsMap] = useState({});

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        let companiesData = [];
        if (isAdmin) {
          companiesData = await firebaseService.getAllCompanies();
        } else {
          // Non-admin: get their single company
          const company = await firebaseService.getCompanyById(
            currentUser.companyId
          );
          if (company) companiesData = [company];
        }

        const equipmentData = isAdmin
          ? await firebaseService.getAllEquipment()
          : await firebaseService.getEquipmentByCompanyId(
              currentUser.companyId
            );

        const filterTypesData = await firebaseService.getFilterTypes();

        // Create maps for easy lookup
        const cMap = {};
        companiesData.forEach((c) => (cMap[c.id] = c));

        const ftMap = {};
        filterTypesData.forEach((ft) => (ftMap[ft.id] = ft));

        // Sort equipment by company name, then by truck number
        equipmentData.sort((a, b) => {
          const companyA =
            a.companyId && cMap[a.companyId] ? cMap[a.companyId].name : "";
          const companyB =
            b.companyId && cMap[b.companyId] ? cMap[b.companyId].name : "";

          // Compare company names first
          if (companyA < companyB) return -1;
          if (companyA > companyB) return 1;

          // If same company, compare truck numbers (treat as strings to handle non-numeric parts)
          const truckA = a.truckNumber ? a.truckNumber.toLowerCase() : "";
          const truckB = b.truckNumber ? b.truckNumber.toLowerCase() : "";
          if (truckA < truckB) return -1;
          if (truckA > truckB) return 1;
          return 0;
        });

        setCompaniesMap(cMap);
        setFilterTypeMap(ftMap);
        setCompanies(companiesData);
        setEquipmentList(equipmentData);

        // Fetch equipment metrics for all equipments
        const eqIds = equipmentData.map((eq) => eq.id);
        const allMetrics =
          await firebaseService.getEquipmentMetricsByEquipmentIds(eqIds);
        const mMap = {};
        allMetrics.forEach((m) => {
          mMap[m.equipmentId] = m;
        });
        setMetricsMap(mMap);
      } catch (error) {
        showModalError(error.message);
      }
    }
    fetchData();
  }, [isAdmin, currentUser, showModalError]);

  useEffect(() => {
    let filtered = equipmentList;

    // Filter by company if selected
    if (selectedCompanyFilter) {
      filtered = filtered.filter(
        (eq) => eq.companyId === selectedCompanyFilter
      );
    }

    // Filter by search term (truckNumber or filterSerialNumber)
    if (searchTerm) {
      filtered = filtered.filter(
        (eq) =>
          (eq.truckNumber &&
            eq.truckNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (eq.filterSerialNumber &&
            eq.filterSerialNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
    }

    setFilteredEquipment(filtered);
  }, [equipmentList, selectedCompanyFilter, searchTerm]);

  // Fetch all fuel logs and service logs for filtered equipment (for the current page)
  useEffect(() => {
    async function fetchLogs() {
      if (filteredEquipment.length === 0) {
        setFuelLogsMap({});
        setServiceLogsMap({});
        return;
      }

      const eqIds = filteredEquipment.map((eq) => eq.id);

      const allFuelLogs = await firebaseService.getFuelLogsByEquipmentIds(
        eqIds
      );
      const allServiceLogs = await firebaseService.getServiceLogsByEquipmentIds(
        eqIds
      );

      // Group fuel logs by eq
      const fuelGrouped = {};
      allFuelLogs.forEach((log) => {
        if (!fuelGrouped[log.equipmentId]) fuelGrouped[log.equipmentId] = [];
        fuelGrouped[log.equipmentId].push(log);
      });

      // Group service logs by eq and find the latest one
      const serviceGrouped = {};
      allServiceLogs.forEach((log) => {
        if (!serviceGrouped[log.equipmentId])
          serviceGrouped[log.equipmentId] = [];
        serviceGrouped[log.equipmentId].push(log);
      });

      // Sort service logs desc by date and pick latest
      for (const eqId in serviceGrouped) {
        serviceGrouped[eqId].sort(
          (a, b) =>
            b.filterChangeDate.toMillis() - a.filterChangeDate.toMillis()
        );
        serviceGrouped[eqId] = serviceGrouped[eqId][0]; // latest
      }

      setFuelLogsMap(fuelGrouped);
      setServiceLogsMap(serviceGrouped);
    }

    fetchLogs();
  }, [filteredEquipment]);

  // Pagination
  const totalPages = Math.ceil(filteredEquipment.length / itemsPerPage);
  const paginatedEquipment = filteredEquipment.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function renderPagination() {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  }

  // Calculate sums and averages from logs
  function calculateFuelStats(eq, logs) {
    if (!logs || logs.length === 0) {
      return {
        totalGallons: 0,
        totalDefGallons: 0,
        avgMPGAllTime: eq.estMPG || "",
        avgMPG30Days: "",
      };
    }

    const totalGallons = logs.reduce((sum, l) => sum + (l.gallons || 0), 0);
    const totalDefGallons = logs.reduce(
      (sum, l) => sum + (l.defGallons || 0),
      0
    );

    // AvgMPGAllTime: use calculatedMPG from all logs that have it
    const mpgLogs = logs.filter((l) => l.calculatedMPG != null);
    let avgMPGAllTime;
    if (mpgLogs.length > 0) {
      const sumMPG = mpgLogs.reduce((sum, l) => sum + l.calculatedMPG, 0);
      avgMPGAllTime = sumMPG / mpgLogs.length;
    } else {
      avgMPGAllTime = eq.estMPG || "";
    }

    // Last 30 days logs
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const last30daysLogs = logs.filter(
      (l) => l.logDate.toDate() >= thirtyDaysAgo && l.calculatedMPG != null
    );

    let avgMPG30Days;
    if (last30daysLogs.length > 0) {
      const sum30 = last30daysLogs.reduce((sum, l) => sum + l.calculatedMPG, 0);
      avgMPG30Days = sum30 / last30daysLogs.length;
    } else {
      avgMPG30Days = "";
    }

    return {
      totalGallons,
      totalDefGallons,
      avgMPGAllTime,
      avgMPG30Days,
    };
  }

  function getTextColorForRemaining(remaining, max) {
    if (!max || max <= 0 || !remaining) return "";
    const ratio = remaining / max;
    if (ratio < 0.1) {
      return "red";
    } else if (ratio < 0.3) {
      return "yellow";
    }
    return "";
  }

  // Export JSON
  const handleExportJSON = () => {
    // Use filteredEquipment instead of paginatedEquipment
    const dataToExport = filteredEquipment.map((eq) => {
      const metrics = metricsMap[eq.id] || {};
      return {
        truckNumber: eq.truckNumber,
        engineMakeModel: eq.engineMakeModel,
        estMPG: eq.estMPG,
        filterType: eq.filterTypeId ? filterTypeMap[eq.filterTypeId]?.name : "",
        company:
          eq.companyId && companiesMap[eq.companyId]
            ? companiesMap[eq.companyId].name
            : "Unassigned",
        filterSerialNumber: eq.filterSerialNumber,
        initialMileage: eq.initialMileage,
        initialHours: eq.initialHours,
        maxFilterHours: metrics.filterLifeHoursMax,
        remainingFilterHours: metrics.filterLifeHoursRemaining,
        maxFilterMiles: metrics.filterLifeMileageMax,
        remainingFilterMiles: metrics.filterLifeMileageRemaining,
        engineHoursAtInstall: metrics.engineHours,
        mileageAtInstall: metrics.mileage,
      };
    });
    const jsonStr = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "equipment_data.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  // Export CSV
  const handleExportCSV = () => {
    const headers = [
      "Truck Number",
      "Engine Make/Model",
      "Est MPG",
      "Filter Type",
      "Company",
      "Filter S/N",
      "Initial Mileage",
      "Initial Hours",
      "Max Filter Hours",
      "Remaining Filter Hours",
      "Max Filter Miles",
      "Remaining Filter Miles",
      "Engine Hours (At Install)",
      "Mileage (At Install)",
    ];
    const rows = filteredEquipment.map((eq) => {
      const metrics = metricsMap[eq.id] || {};
      return [
        eq.truckNumber || "",
        eq.engineMakeModel || "",
        eq.estMPG || "",
        eq.filterTypeId ? filterTypeMap[eq.filterTypeId]?.name || "" : "",
        eq.companyId && companiesMap[eq.companyId]
          ? companiesMap[eq.companyId].name
          : "Unassigned",
        eq.filterSerialNumber || "",
        eq.initialMileage || "",
        eq.initialHours || "",
        metrics.filterLifeHoursMax || "",
        metrics.filterLifeHoursRemaining || "",
        metrics.filterLifeMileageMax || "",
        metrics.filterLifeMileageRemaining || "",
        metrics.engineHours || "",
        metrics.mileage || "",
      ];
    });

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.map((v) => `"${v}"`).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const a = document.createElement("a");
    a.href = encodedUri;
    a.download = "equipment_data.csv";
    a.click();
  };

  // Export XLSX
  const handleExportXLSX = () => {
    const wsData = [
      [
        "Truck Number",
        "Engine Make/Model",
        "Est MPG",
        "Filter Type",
        "Company",
        "Filter S/N",
        "Initial Mileage",
        "Initial Hours",
        "Max Filter Hours",
        "Remaining Filter Hours",
        "Max Filter Miles",
        "Remaining Filter Miles",
        "Engine Hours (At Install)",
        "Mileage (At Install)",
      ],
    ];

    filteredEquipment.forEach((eq) => {
      const metrics = metricsMap[eq.id] || {};
      wsData.push([
        eq.truckNumber || "",
        eq.engineMakeModel || "",
        eq.estMPG || "",
        eq.filterTypeId ? filterTypeMap[eq.filterTypeId]?.name || "" : "",
        eq.companyId && companiesMap[eq.companyId]
          ? companiesMap[eq.companyId].name
          : "Unassigned",
        eq.filterSerialNumber || "",
        eq.initialMileage || "",
        eq.initialHours || "",
        metrics.filterLifeHoursMax || "",
        metrics.filterLifeHoursRemaining || "",
        metrics.filterLifeMileageMax || "",
        metrics.filterLifeMileageRemaining || "",
        metrics.engineHours || "",
        metrics.mileage || "",
      ]);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, "EquipmentData");
    XLSX.writeFile(wb, "equipment_data.xlsx");
  };

  return (
    <Container
      fluid
      className="mt-3"
      style={{ overflowX: "auto", overflowY: "auto", height: "auto" }}
    >
      <div className="d-flex flex-wrap align-items-center mb-3">
        {isAdmin && (
          <div className="me-3">
            <Form.Select
              value={selectedCompanyFilter}
              onChange={(e) => setSelectedCompanyFilter(e.target.value)}
            >
              <option value="">All Companies</option>
              {companies.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Form.Select>
          </div>
        )}
        <InputGroup style={{ maxWidth: "300px" }}>
          <FormControl
            placeholder="Search by Truck # or Filter S/N"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>

        {/* Export Buttons */}
        <div className="ms-auto d-flex align-items-center">
          <Button variant="primary" className="me-2" onClick={handleExportXLSX}>
            Export XLSX
          </Button>
          <Button variant="primary" className="me-2" onClick={handleExportCSV}>
            Export CSV
          </Button>
          <Button variant="primary" onClick={handleExportJSON}>
            Export JSON
          </Button>
        </div>
      </div>

      <Table
        striped
        bordered
        hover
        variant="dark"
        responsive
        style={{ minWidth: "2000px" }}
      >
        <thead>
          <tr>
            <th>Truck Number</th>
            <th>Engine Make/Model</th>
            <th>Est MPG</th>
            <th>Filter Type</th>
            <th>Est. Next Filter Change Date</th>
            <th>Company</th>
            <th>Filter S/N</th>
            <th>Initial Mileage</th>
            <th>Initial Hours</th>
            <th>Total Fuel Gallons</th>
            <th>Total DEF Gallons</th>
            <th>Avg MPG (All Time)</th>
            <th>Avg MPG (Last 30 Days)</th>
            <th>Latest Service Log Mileage</th>
            <th>Latest Service Log Engine Hours</th>
            <th>Latest Service Log Date</th>
            <th>Max Filter Hours</th>
            <th>Remaining Filter Hours</th>
            <th>Max Filter Miles</th>
            <th>Remaining Filter Miles</th>
            <th>Engine Hours (At Filter Install/Change)</th>
            <th>Mileage (At Filter Install/Change)</th>
          </tr>
        </thead>
        <tbody>
          {paginatedEquipment.map((eq) => {
            const logs = fuelLogsMap[eq.id] || [];
            const {
              totalGallons,
              totalDefGallons,
              avgMPGAllTime,
              avgMPG30Days,
            } = calculateFuelStats(eq, logs);

            const serviceLog = serviceLogsMap[eq.id];
            const latestMileage = serviceLog
              ? serviceLog.currentMileage.toLocaleString()
              : "";
            const latestHours = serviceLog ? serviceLog.engineHours : "";
            const latestDate =
              serviceLog && serviceLog.filterChangeDate
                ? formatDateForDisplay(
                    fromFirestoreTimestamp(serviceLog.filterChangeDate)
                  )
                : "";

            const companyName =
              eq.companyId && companiesMap[eq.companyId]
                ? companiesMap[eq.companyId].name
                : "Unassigned";

            const filterTypeName =
              eq.filterTypeId && filterTypeMap[eq.filterTypeId]
                ? filterTypeMap[eq.filterTypeId].name
                : "";

            // Get metrics from metricsMap
            const metrics = metricsMap[eq.id];

            const maxFilterHours = metrics?.filterLifeHoursMax ?? 0;
            const remainingFilterHours = metrics?.filterLifeHoursRemaining ?? 0;
            const maxFilterMiles = metrics?.filterLifeMileageMax ?? 0;
            const remainingFilterMiles =
              metrics?.filterLifeMileageRemaining ?? 0;
            const engineHoursAtInstall = metrics?.engineHours ?? "";
            const mileageAtInstall =
              metrics?.mileage != null
                ? formatNumberWithCommas(metrics.mileage)
                : "";

            const remainingHoursColor = getTextColorForRemaining(
              remainingFilterHours,
              maxFilterHours
            );
            const remainingMilesColor = getTextColorForRemaining(
              remainingFilterMiles,
              maxFilterMiles
            );

            return (
              <tr key={eq.id}>
                <td>{eq.truckNumber || ""}</td>
                <td>{eq.engineMakeModel || ""}</td>
                <td>{eq.estMPG || ""}</td>
                <td>{filterTypeName}</td>
                <td>
                  {eq.calcDateNextFilterChange
                    ? formatDateForDisplay(
                        fromFirestoreTimestamp(eq.calcDateNextFilterChange)
                      )
                    : ""}
                </td>
                <td>{companyName}</td>
                <td>{eq.filterSerialNumber || ""}</td>
                <td>
                  {eq.initialMileage != null
                    ? formatNumberWithCommas(eq.initialMileage)
                    : ""}
                </td>
                <td>
                  {eq.initialHours != null
                    ? formatNumberWithCommas(eq.initialHours)
                    : ""}
                </td>
                <td>{formatNumberWithCommas(totalGallons)}</td>
                <td>{formatNumberWithCommas(totalDefGallons)}</td>
                <td>
                  {typeof avgMPGAllTime === "number"
                    ? avgMPGAllTime.toFixed(2)
                    : avgMPGAllTime}
                </td>
                <td>
                  {typeof avgMPG30Days === "number"
                    ? avgMPG30Days.toFixed(2)
                    : avgMPG30Days}
                </td>
                <td>{latestMileage}</td>
                <td>{latestHours}</td>
                <td>{latestDate}</td>
                <td>{maxFilterHours || ""}</td>
                {remainingHoursColor !== "" ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {remainingHoursColor === "red"
                          ? "Less than 10%"
                          : remainingHoursColor === "yellow"
                          ? "Less than 30%"
                          : "greater than 30%"}{" "}
                        of filter life.
                      </Tooltip>
                    }
                  >
                    <td style={{ color: remainingHoursColor }}>
                      <span>{remainingFilterHours || ""}</span>
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td style={{ color: remainingHoursColor }}>
                    <span>{remainingFilterHours || ""}</span>
                  </td>
                )}
                <td>{maxFilterMiles || ""}</td>
                {remainingMilesColor !== "" ? (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      remainingMilesColor !== "" ? (
                        <Tooltip>
                          {remainingMilesColor === "red"
                            ? "Less than 10%"
                            : remainingMilesColor === "yellow"
                            ? "Less than 30%"
                            : "greater than 30%"}{" "}
                          of filter life.
                        </Tooltip>
                      ) : null
                    }
                  >
                    <td style={{ color: remainingMilesColor }}>
                      {remainingFilterMiles || ""}
                    </td>
                  </OverlayTrigger>
                ) : (
                  <td style={{ color: remainingMilesColor }}>
                    {remainingFilterMiles || ""}
                  </td>
                )}
                <td>{engineHoursAtInstall}</td>
                <td>{mileageAtInstall}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {renderPagination()}
    </Container>
  );
}

export default EquipmentAll;
